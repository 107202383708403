import VueSlickCarousel from 'vue-slick-carousel'
import Form from '../form/Form.vue'
import api from "@/api/team";

export default {
	components: {VueSlickCarousel, Form},
	data() {
		return {
			settings: {
				dots: false,
				arrows: true,
				"focusOnSelect": true,
				"speed": 500,
				"slidesToShow": 4,
				"slidesToScroll": 4,
				"touchThreshold": 10,
				"responsive": [
					{
						"breakpoint": 1700,
						"settings": {
							"slidesToShow": 2,
							"slidesToScroll": 2,
							"infinite": true,
							"dots": true
						}
					},
					{
						"breakpoint": 1300,
						"settings": {
							"slidesToShow": 1,
							"slidesToScroll": 1,
							"initialSlide": 1,
							"dots": true

						}
					},
					{
						"breakpoint": 600,
						"settings": {
							arrows: false,
							"slidesToShow": 1,
							"slidesToScroll": 1,
							"initialSlide": 1,
							"dots": true,
							"centerMode": true,


						}
					},
				]
			},
		}
	},
	metaInfo() {
		return {
			title: this.currentWorker?.metaTitle ?? 'Worker title',
			meta: [
				{ name: 'description', content: this.currentWorker?.metaDescription ?? 'Worker description' },
				{ name: 'keywords', content: this.currentWorker?.metaKeywords ?? 'Worker keywords' },
			]
		}
	},
	created() {
		api.getAll(this)
	},
	computed: {
		arrTeams () {
			if (this.state.team?.data) {
				return this.state.team.data
			}
			return []
		},
		currentWorker() {
			if (this.state.team?.data) {
				return this.state.team.data.find(worker => worker.urlName === this.$route.params.urlName)
			}
			return {}
		}
	}
}
